<template>
    <div class="funanalysis">
        <analysisHeader></analysisHeader>
        <div class="fabox">
            <div class="identification" v-if="detectionData.headType == 'organ'">
                <div class="identHed organ">
                    <div class="hedImg">
                        <img class="img" :src="detectionData.img" alt="">
                    </div>
                    <div class="organImg" :style="{'color': detectionData.color}">
                        <div class="title">{{detectionData.BelongOrgan}}</div>
                    </div>
                </div>
            </div>
            <div class="identification" v-else>
                <div class="identHed">
                    <div class="hedImg">
                        <img class="img" :src="detectionData.img" alt="">
                    </div>
                    <div class="text" :style="{'color': detectionData.color}">
                        <div class="title">{{detectionData.title}}</div>
                        <div class="subtitle">{{detectionData.translate}}</div>
                    </div>
                </div>
            </div>

            <!-- 核心分析 -->
            <div class="coreAnalysis" v-if="'coreData' in detectionData">
                <div class="functStatus" v-for="(item, index) in detectionData.coreData" :key="index">
                    <div class="funCont" :style="{paddingBottom: item.text ? '25px' : '0'}">
                        <div class="fn_left">
                            <div class="topicTl">{{item.Category}}</div>
                        </div>
                        <div class="fn_right">
                            <div class="fn_item">
                                <div class="strip" :style="{'backgroundColor': item.bgaColor, 'width': item.proportion + '%'}"></div>
                                <div class="fn_degree">
                                    <img class="img" :src="degree4" alt="">
                                    <div class="bell" :style="{left: item.proportion+ '%'}"></div>
                                    <div class="belltext" :style="{left: item.proportion+ '%'}">{{item.text}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="factTad">
                    <div class="factor" v-for="(item, index) in detectionData.coreData" :key="index">
                        <div class="faitem">
                            <div class="faleft">
                                <div class="theme thetitle">
                                    <div class="marRight">{{item.title == '无' ? '' : item.title}}</div>
                                </div>
                                <div class="theme">
                                    {{item.Category}}
                                </div>
                            </div>
                            <div class="faright">
                                <div class="degree" :style="{background: `linear-gradient(to right, ${item.color},${item.color2})`}">
                                    <div class="bell" :style="{right: item.proportion + '%'}">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div id="main" class="histogram" v-if="'histogram' in detectionData"></div>

            <!-- 器官功能分析 -->
            <div class="organFn" v-if="detectionData.headType == 'organ'">
                <!-- 功能分析 -->
                <div class="alysisTitle">
                    <div class="title">{{detectionData.BelongOrgan}}功能状态分析</div>
                    <div class="subtitle">FUNCTIONAL-STATUS-ANALYSIS</div>
                </div>
                <div class="function">
                    <div class="Tips">
                        <div class="tipText">
                            <div class="te1">正常</div>
                            <div class="te2">不稳定</div>
                        </div>
                        <div class="tipImg">
                            <img class="img" :src="degreeTip2" alt="">
                        </div>
                    </div>
                    <div class="factorThree">
                        <div class="faitem">
                            <div class="faleft">
                                <div class="theme">
                                    <!-- <div>{{detectionData.BelongOrgan}}功能状态</div> -->
                                    <div>功能状态</div>
                                </div>
                            </div>
                            <div class="faright">
                                <div class="degree degree2">
                                    <div class="bell" :style="{left: detectionData.function.proportion + '%'}">
                                        <img class="img" :src="warnIcon" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 风险分析 -->
                <div class="alysisTitle">
                    <div class="title">{{detectionData.BelongOrgan}}健康风险分析</div>
                    <div class="subtitle">FUNCTIONAL-STATUS-ANALYSIS</div>
                </div>
                <div v-if="'child' in detectionData">
                    <div v-for="(item, index) in detectionData.child" :key="index">
                        <div class="functStatus" v-if="index < 3">
                            <div class="funCont" :style="{paddingBottom: item.text ? '25px' : '0'}">
                                <div class="fn_left">
                                    <div class="topicTl">{{item.Category}}</div>
                                </div>
                                <div class="fn_right">
                                    <div class="fn_item">
                                        <div class="strip" :style="{'backgroundColor': item.bgaColor, 'width': item.proportion + '%'}"></div>
                                        <div class="fn_degree">
                                            <img class="img" :src="degree4" alt="">
                                            <div class="bell" :style="{left: item.proportion + '%'}"></div>
                                            <div class="belltext" :style="{left: item.proportion + '%'}">{{item.text}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="remark" v-if="item.IndexExplain && item.bgaColor != '#71A241'">
                                <span style="color: red">*</span>
                                {{item.IndexExplain}}
                            </div>
                            <div class="warning" v-if="item.warningImg && item.warningShow">
                                <div class="warningImg">
                                    <img class="img" :src="item.warningImg" alt="">
                                </div>
                                <div class="warningText">
                                    <div class="text1">{{item.warningText}}</div>
                                    <div class="text2">{{item.warningText2}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 脂肪代谢分析 -->
            <div class="fa_analysis" v-if="detectionData.factorAnalysis">
                <div class="bodyAnaly" id="bodyAnaly">
                    <img class="img" :src="bodyUrl" alt="">
                    <div class="analyItem" v-for="(item, index) in detectionData.analysisArr" :key="index" :id="`item${index}`" :style="{left:item.left, right:item.right, top: item.top, background: item.color ? item.color : '#71A241'}">
                        <div v-for="(el, i) in item.text" :key="i">
                            {{el}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- 身体矩阵 -->
            <div class="matrix" v-if="'matrix' in detectionData">
                <div class="matrImg">
                    <img class="img" :src="matrixUrl" alt="">
                    <div class="matQuota" v-for="(item, index) in detectionData.matrixArr" :key="index" :style="{top: item.top, left: item.left, right: item.right}">
                        <div class="leabel">
                            <div class="title">{{item.title}}</div>
                            <div>{{item.subTitle}}</div>
                        </div>
                        <div class="contMat">
                            <div class="matItem" v-for="(el,i) in item.child" :key="i" :style="{color: el.color}">{{el.Category}}</div>
                            <div v-if="!item.coreShow">
                                <div class="matItem">{{item.summary2}}</div>
                                <div class="matItem">{{item.summary3}}</div>
                                <div class="matItem">{{item.summary4}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="tipInfo" v-if="detectionData.headType == 'organ'">
                <div class="info">
                    <div class="tipColor5"></div>
                    <div>低风险</div>
                </div>
                <div class="info">
                    <div class="tipColor4"></div>
                    <div>中风险</div>
                </div>
                <div class="info">
                    <div class="tipColor6"></div>
                    <div>高风险</div>
                </div>
            </div> -->

            <!-- 图例 -->
            <div class="tipInfo" v-if="detectionData.tips">
                <div class="info">
                    <div class="tipColor"></div>
                    <div>风险提示，可能有症状或正在发展中;</div>
                </div>
                <div class="info">
                    <div class="tipColor7"></div>
                    <div>风险提示，可能无症状或相对稳定中;</div>
                </div>
                <div class="info">
                    <div class="tipColor3"></div>
                    <div>提示关注，可能是有症状的亚健康或已经存在的健康问题;</div>
                </div>
                <div class="info">
                    <div class="tipColor4"></div>
                    <div>提示关注，可能是无或低症状的亚健康或已经存在的健康问题;</div>
                </div>
                <div class="info">
                    <div class="tipColor5"></div>
                    <div>当下无风险，请保持健康生活方式;</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import analysisHeader from './analysisHeader.vue'
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

export default {
    components: {
        analysisHeader
    },
    data() {
        return {
            warnIcon: `${this.$imgDomain}/www/HealthingCore/bell.png`,
            warnIconCore: `${this.$imgDomain}/www/HealthingCore/bell2.png`,
            degreeTip2: `${this.$imgDomain}/www/HealthingCore/degreeTip2.png`,
            degree4: `${this.$imgDomain}/www/HealthingCore/degree4.png`,
            bodyUrl: `${this.$imgDomain}/www/HealthingCore/body.png`,
            matrixUrl: `${this.$imgDomain}/www/HealthingCore/matrix.png`,
        };
    },
    props:['detectionData'],
    created() {
        
    },
    mounted() {
        this.$nextTick(() => {
            this.histogramConfig();
        })
    },
    methods: {
        histogramConfig(){
            if(!document.getElementById('main')){
                return
            }
            echarts.use([GridComponent, BarChart, CanvasRenderer]);
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            try {
                option = {
                    xAxis: {
                        type: 'category',
                        data: this.detectionData.histogram.xAxisData,
                        axisLabel: {
                            interval: 0,
                            formatter: function(value) {
                                //x轴的文字改为竖版显示
                                var str = value.split("");
                                return str.join("\n");
                            },
                            textStyle: {
                                fontSize: 14,
                            },
                        }

                    },
                    yAxis: {
                        type: 'value',
                        axisLine:{
                            show: true //y轴线
                        },
                        axisLabel : {
                            formatter: function(){
                                return "";
                            }
                        },
                        max: 1,
                        splitArea:{
                            show: true,
                            areaStyle: {
                                color: [
                                    '#C6E1EA',
                                    '#E1E9EB'
                                ]
                            }
                        }

                    },
                    series: [
                        {
                            data: this.detectionData.histogram.seriesDate,
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        if(params.value > 0.8){
                                            return "#74A841";
                                        } else if(params.value > 0.6 && params.value <= 0.8) {
                                            return new echarts.graphic.LinearGradient(
                                                0, 1, 0, 0, [
                                                    {
                                                        offset: 0,
                                                        color: '#74A841'
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#E8EA70'
                                                    }
                                                ])
                                        } else if(params.value > 0.425 && params.value <= 0.6) {
                                            return new echarts.graphic.LinearGradient(
                                                0, 1, 0, 0, [
                                                    {
                                                        offset: 0,
                                                        color: '#E8EA70'
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#D89F15'
                                                    }
                                                ])
                                        } else {
                                            return new echarts.graphic.LinearGradient(
                                                0, 1, 0, 0, [
                                                    {
                                                        offset: 0,
                                                        color: '#B21E23'
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#86272A'
                                                    }
                                                ])
                                        }
                                    },
                                    label: {
                                        show: false,		//开启显示
                                        position: 'top',	//在上方显示
                                        textStyle: {	    //数值样式
                                            color: 'black',
                                            fontSize: 8
                                        }
                                    }
                                }
                            }
                        }
                    ]
                };
            } catch {
                
            }
           
            option && myChart.setOption(option);
            this.$forceUpdate();
        },
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.funanalysis {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    overflow-x: hidden;
    height: 860px;
    background: #F5F5F5;
    border-radius: 0 0 20px 20px;
    border-top: 10px solid #fff;
    position: relative;
    .fabox {
        background: #F5F5F5;
        border-top: 5px solid #fff;
        width: 100%;
        border-radius: 0 0 20px 20px;
        padding: 0 10px;
        // 标题
        .identification {
            width: 100%;
            margin-top: 50px;
            .identImg {
                width: 60px;
                height: 60px;
            }
            .identTitle {
                display: flex;
                flex-direction: column;
                align-items: center;
                .title {
                    font-size: 20px;
                    font-weight: 700;
                    margin-top: 20px;
                }
                .subtitle {
                    margin-top: 5px;
                }
            }
            .identHed {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 65px;
                width: 100%;
                background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag2.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size:100% 100%;
                position: relative;
                .hedImg {
                    position: absolute;
                    left: 0;
                    width: 22%;
                    height: 100%;
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    height: 100%;
                    .title {
                        font-size: 17px;
                        font-weight: 700;
                    }
                    .subtitle {
                        width: 100%;
                        margin-top: 5px;
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }
            .organ {
                justify-content: center;
                .organImg {
                    width: 55px;
                    height: 55px;
                    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/theme.png');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size:100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .title {
                        font-weight: 700;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        // 核心
        .coreAnalysis {
            width: 100%;
            .factTad {
                width: 100%;
                margin-top: 20px;
                .factor {
                    background: #E8F1F3;
                    padding: 0 10px;
                    .faitem {
                        display: flex;
                        padding: 10px 0;
                        .faleft {
                            width: 20%; 
                            padding-right: 5px;
                            min-height: 60px;
                            border-right: 1px solid #000;
                            .theme {
                                display: flex;
                                .translate {
                                    width: 100%;
                                    overflow:hidden;
                                    text-overflow: ellipsis;
                                }
                                .marRight {
                                    min-width: 40px;
                                    min-height: 20px;
                                    text-align: center;
                                }
                                .tips {
                                    width: 40px;
                                    height: 32px;
                                    border-radius: 40px;
                                    overflow: hidden;
                                    background: #DFF1F3;
                                }
                                .risks {
                                    width: calc(100% - 40px);
                                    .ellipsis {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }
                                }
                                
                            }
                            .thetitle {
                                font-size: 16px;
                                margin-bottom: 5px;
                            }
                        }
                        .faright {
                            width: 80%;
                            display: flex;
                            align-items: center;
                            .degree {
                                width: 100%;
                                height: 30px;
                                // background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree.png');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size:100% 100%;
                                position: relative;
                            }
                            // .degree2 {
                            //     background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree2.png');
                            // }
                            // .degree3 {
                            //     background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree3.png');
                            // }
                            .bell {
                                position: absolute;
                                top: 4px;
                                width: 24px;
                                // height: 50px;
                            }   
                        }
                    }
                }
            }
        }
        //柱状
        .histogram {
            width: 100%;
            height: 400px;
            /deep/ canvas {
                width: 110% !important;
                left: -5% !important;
            }
        }
        // 器官功能
        .organFn,
        .coreAnalysis {
            .alysisTitle {
                text-align: center;
                height: 40px;
                margin-top: 20px;
                .title {
                    font-size: 16px;
                }
            }
            .Tips {
                width: 100%;
                background: #E8F1F3;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 20px;
                .tipText {
                    width: 80%;
                    display: flex;
                    justify-content: space-around;
                    margin: 5px 0;
                    padding-right: 5px;
                    text-align: center;
                    .te1 {
                        width: 25%;
                    }
                    .te2 {
                        width: 75%;
                    }
                }
                .tipImg {
                    width: 79.5%;
                    padding-right: 5px;
                }
            }
            .function {
                background: #E8F1F3;
                padding: 5px 0;
                .factorThree {
                    background: #E8F1F3;
                    padding: 0 5px;
                    .faitem {
                        display: flex;
                        min-height: 35px;
                        margin: 10px 0;
                        .faleft {
                            width: 20%; 
                            padding-right: 5px;
                            border-right: 1px solid #000;
                            .theme {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                height: 100%;
                                .translate {
                                    width: 100%;
                                    overflow:hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        .faright { 
                            width: 80%;
                            display: flex;
                            align-items: center;
                            .degree {
                                width: 100%;
                                height: 30px;
                                background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree.png');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size:100% 100%;
                                position: relative;
                            }
                            .degree2 {
                                background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree2.png');
                            }
                            .degree3 {
                                background-image: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/degree3.png');
                            }
                            .bell {
                                position: absolute;
                                top: -18px;
                                width: 24px;
                            }
                        }
                    }
                }
            }
            
            .functStatus {
                background: #E8F1F3;
                padding: 10px;
                margin-top: 10px;
                .funCont {
                    display: flex;
                    .fn_left {
                        width: 20%;
                        padding-right: 5px;
                        border-right: 1px solid #000;
                        display: flex;
                        align-items: center;
                        .topicTl {
                            width: 100%; 
                            text-align: end;
                            // text-overflow: ellipsis;
                            // white-space: nowrap;
                            // overflow: hidden;
                        }
                        .translate {
                            width: 100%;   
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }
                    .fn_right {
                        width: 80%;
                        .fn_item {
                            padding: 5px 0;
                            .strip {
                                height: 15px;
                            }
                            .fn_degree {
                                width: 100%;
                                height: 10px;
                                position: relative;
                                margin-top: 20px;
                                .bell {
                                    position: absolute;
                                    top: 1px;
                                    width: 8px;
                                    height: 8px;
                                    transform: translateX(-50%);;
                                    border-radius: 10px;
                                    background-color: #000;
                                }
                                .belltext {
                                    position: absolute;
                                    transform: translateX(-50%);
                                    top: 15px;
                                }
                                .bell::before {
                                    content: "";
                                    position: absolute;
                                    top: -25px;
                                    left: 4px;
                                    height: 25px;
                                    border-left: 1px dashed #000;
                                }
                            }
                            
                        }
                    }
                }
                .remark {
                    width: 100%;
                    margin-top: 10px;
                    padding: 2px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ccc;
                    color: #000;
                }
                .warning {
                    display: flex;
                    margin-top: 10px;
                    .warningImg  {
                        width: 36px;
                    }
                    .warningText {
                        margin-left: 5px;
                        .text1 {
                            font-weight: 700;
                        }   
                    }
                }
            }

        }
        // 图例
        .tipInfo {
            position: absolute;
            bottom: 10px;
            width: 100%;
            // width: 100%;
            // display: flex;
            // justify-content: space-around;
            .info {
                display: flex;
                justify-content: flex-start;
                margin-top: 10px;
                .tipColor {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #A12924,
                }
                .tipColor2 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #B21E23;
                }
                .tipColor3 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #BF8341,
                }
                .tipColor4 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #E8E080,
                }
                .tipColor5 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #71A241,
                }
                .tipColor6 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #A12924,
                }
                .tipColor7 {
                    min-width: 40px;
                    margin-right: 5px;
                    background: #c35d61,
                }
            }
        }
        // 脂肪代谢
        .fa_analysis {
            display: flex;
            justify-content: center;
            margin-top: 60px;
            .bodyAnaly {
                width: 140px;
                position: relative;
                .analyItem {
                    position: absolute;
                    width: 80px;
                    height: 70px;
                    border-radius: 10px;
                    font-size: 14px;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        // 身体矩阵
        .matrix {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .matrImg {
                margin: 150px 0;
                width: 45%;
                position: relative;
                .matQuota {
                    position: absolute;
                    text-align: center;
                    .leabel {
                        .title {
                            font-weight: 700;
                            font-size: 16px;
                        }
                    }
                    .contMat {
                        font-size: 14px;
                        .matItem {
                            max-width: 150px;
                            color: #D89F15;
                        }
                    }
                }
            }
        }
        .img {
            width: 100%;
            height: 100%;
        }
    }

    
}
</style>